import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
    IMGBASEAPIURL,
    ContactMobile,
    ContactWhatsapp,
} from '../../config';
import './FooterInstitute.css';

function Footer({ className }) {
    
    useEffect(() => {
        // FetchCatiData();
    }, []);

    return (
        <>
            <section className={`appie-footer-area ${className || ''}`}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div
                                className="footer-copyright mt-0 d-flex"
                                style={{
                                    justifyContent: 'left',
                                    borderTop: '0px solid #383838',
                                }}
                            >
                                <div className="copyright-text">
                                    <p>Design & Developed by: Dubey Photo Studio</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div
                                className="footer-copyright mt-0 d-flex"
                                style={{
                                    justifyContent: 'right',
                                    borderTop: '0px solid #383838',
                                }}
                            >
                                <div className="copyright-text">
                                    <Link to="tel:+919907952993">Call Us +91 99079 52993</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div className="developed-text d-none">
                <p>
                    Design & Developed by
                    <a
                        href="//sjain.io/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img
                            src={`${IMGBASEAPIURL}logo/sjain-logo-white.png`}
                            alt="Sjain Ventures"
                        />
                    </a>
                </p>
            </div>

            <div className="d-none">
                <a
                    target="_WHATAPP"
                    href={`https://api.whatsapp.com/send?phone=${ContactWhatsapp}&text=Hello,%20Sending%20you%20message%20from%20https://docstainstitute.com/`}
                    className="whatsapp_us"
                >
                    <img src={`${IMGBASEAPIURL}whatsappus.png`} alt="" />
                </a>
            </div>
            <div className="d-none">
                <a href={`tel:${ContactMobile}`} className="call_us">
                    <img src={`${IMGBASEAPIURL}callus.png?v=0.1`} alt="" />
                </a>
            </div>
        </>
    );
}

export default Footer;
