import React, { useEffect, useState, useRef } from 'react';
import { Gallery } from "react-grid-gallery";

// import ImageGallery from "react-image-gallery";

import Slider from 'react-slick';
import { IMGBASEAPIURL, BASEURL } from '../../../config';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import './gallery.css';

function GalleryMain() {
    const sliderRef = useRef();
    const settings = {
        autoplay: false,
        arrows: true,
        dots: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };
    const [Show, setShow] = useState(false);
    const dataPage = [];
    for (let i = 1; i <= 15; i++) {
        dataPage.push(i);
    }
    const [AllPage] = useState(dataPage);
    const [Page, setPage] = useState(1);
    const [Index, setIndex] = useState(0);
    const data = [];
    for (let i = 1; i <= 15 * Page; i++) {
        data.push({ big : `/assets/images/img_1 (${i}).JPG`, src: `/assets/images/thumb/imgthumb (${i}).JPG`, caption: `Photo No. ${i}` });
    }
    const dataBig = [];
    for (let i = 1; i <= 270 * Page; i++) {
        dataBig.push({ big : `/assets/images/img_1 (${i}).JPG`, src: `/assets/images/thumb/imgthumb (${i}).JPG`, originalTitle  : `Photo No. ${i}` });
    }
    const [ShowImage, setShowImage] = useState(data);
    const [ShowAllImage] = useState(dataBig);

    function showPageImage(v) {
        setPage(v);
        const data = [];
        for (let i = (15 * ( v - 1 ) + 1); i <= 15 * v; i++) {
            data.push({ big: `/assets/images/img_1 (${i}).JPG`, src: `/assets/images/thumb//imgthumb (${i}).JPG`, caption: `Photo No. ${i}` });
        }
        setShowImage(data);
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    useEffect(() => {
        // tes
        // setShow(true);
    }, []);

    // run the function here
    function handleClick(index) {
        setIndex(index);
        setShow(true);
    }
    // run the function here

    return (
        <>
            <section
                className="corporate-learning p-0"
                data-overlay="6"
                style={{
                    backgroundImage: `url(${IMGBASEAPIURL}institute/contactus/gallery.jpg)`,
                    backgroundPosition: 'center',
                }}
            >
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="headBlock">
                                {/* <h3 className="mt-3 mb-1">Gallery</h3> */}
                                <h1 className="mt-3 text-white mb-1">Yadav Family</h1>
                                <p className="mb-3">Shrinay 2nd Birthday celebrated on 17th Feb</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* for the big view */}
            {ShowAllImage && ShowAllImage.length > 0  ? (
                <div className="imagePopUPBox" style={{ display: Show ? '' : 'none' }}>
                    <h3
                        className="imagePopUPClose"
                        onClick={() =>
                            setShow(false)
                        }
                    >X</h3>
                    {/* <ImageGallery items={dataBig} loading="lazy" /> */}
                    <div className="imagePopUPIndex d-none">
                        <b>{ShowAllImage[Index].caption}</b>
                        <br />
                        {Index + 1}/{ShowAllImage.length}
                    </div>
                    <Slider ref={sliderRef} {...settings}>
                        {ShowAllImage.map((cti, i) => (
                            <div
                                key={cti.src} title={cti.caption}
                                onClick={() =>
                                    setIndex(i)
                                }
                            >
                                <div className="appie-features-content">
                                    <img src={cti.src} alt={cti.caption} />
                                </div>
                            </div>
                        ))}
                    </Slider>
                    {/* <img className="imagePopUP" src={ShowImage[Index].src} alt={ShowImage[Index].caption} /> */}
                    <div className="imagePopUPSlider"></div>
                </div>
            ) : (
                ''
            )}
            
            {/* for the big view */}
            <section className="galleryBlock p-0">
                <div className="container-fluid bg-dark">
                    {ShowImage && ShowImage.length > 0 ? (
                        <Gallery images={ShowImage}  onClick={handleClick} />
                    ) : (
                        <center>
                            <img style={{}} className="m-5" width="50px" height="50px" src={`${BASEURL}/loader.gif`}  alt="" />
                        </center>
                    )}
                </div>
            </section>

            <div className='container-fluid p-3 text-center'>
                         {AllPage.map((s) => (
                            <a
                                href="/"
                                onClick={(e) => {
                                    e.preventDefault();
                                    showPageImage(s);
                                }}
                                    className={`btn btn-ry mr-3 mb-2 btn-${Page === s ? 'success' : 'outline-success text-success'}`}
                            >
                                Page {s}
                            </a>
                         ))}
            </div>
        </>
    );
}

export default GalleryMain;
