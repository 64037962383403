import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
    // IMGBASEAPIURL,
    // CleanUrlWork,
    UPLOADNODEURL,
    BASEAPIURL,
    // GoogleAnylyticsID,
    BASEURL,
    LogOutAll,
    CoursesMainCategory,
} from '../../config';
import logo from '../../assets/images/logo-learn.png';
import StickyMenu from '../../lib/StickyMenu';
import Navigation from '../NavigationLearn';
import './HeaderInstitute.css';
import './rypopup.css';
import { useSelector } from 'react-redux';

function Header({ action, type, pageName }) {
    const CommonData = useSelector((state) => state.commonData)
    const [defaultProfilePicture] = useState('defaultProfilePicture.png');
    const [userData] = useState({ name: '', photo: '' });
    const [OnCart] = useState();
    const [UserType] = useState(localStorage.getItem('usertype'));
    const [UserName] = useState(localStorage.getItem('username'));
    
    // for Search Course By Keywords
    const [ShowKeyWords, setShowKeyWords] = useState([]);
    const [KeySearchCourses, setKeySearchCourses] = useState([]);
    const [KeySearchInstructor, setKeySearchInstructor] = useState([]);
    // for Search Course By Keywords

    // for Category and Sub Category
    const [ShowCatNavigation, setShowCatNavigation] = useState('none');
    const [CourseListLink, setCourseListLink] = useState('/courses/exam-oriented-courses');
    const [CatiData] = useState([]);
    const [SubCatiData] = useState([]);
    const [CourseData] = useState([]);
    const [MainCatId, setMainCatId] = useState('exam');
    const [CatId, setCatId] = useState(0);
    const [SubCatId, setSubCatId] = useState(0);

    useEffect(() => {
        document.title = window.GetDocumentTitle();
        StickyMenu();
        return () => {
            // clearInterval(interval);
        };
    }, []);
    return (
        <>
            <header className="d-none appie-header-area appie-sticky">
                <div className="headSection">
                    <div
                        className={`container-fluid ${
                            Number(localStorage.getItem('screenwidth')) > 767 ? 'p-0' : ''
                        }`}
                    >
                        <div className="header-nav-box">
                            <div className="row align-items-center">
                                <div className="d-lg-none d-block col-12">
                                    <div className="appie-logo-box text-center">
                                        <Link to="/">
                                            <img src={logo} alt="" />
                                        </Link>
                                        <div
                                            id="showMobileSearch"
                                            className="toggle-btn ml-30 d-lg-none d-block"
                                            style={{ right: '40px' }}
                                        >
                                            <i className="fa fa-search" />
                                        </div>
                                        <div
                                            onClick={(e) => action(e)}
                                            className="toggle-btn ml-30 canvas_open"
                                        >
                                            <i className="fa fa-bars" />
                                        </div>
                                        <div className="toggle-btn mr-2">
                                            <Link
                                                className="text-dark"
                                                to="/checkout/cart"
                                                style={{ position: 'relative' }}
                                            >
                                                <i className="fal fa-shopping-cart" />
                                                <span className="cartCounter">
                                                    {Number(OnCart)}
                                                </span>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-7 p-0 col-md-7 col-12">
                                    <div className="appie-search-box">
                                        <Link to="/">
                                            <img
                                                src={logo}
                                                alt=""
                                                style={{
                                                    maxWidth: '185px',
                                                    marginRight: '15px',
                                                }}
                                            />
                                        </Link>
                                        <span
                                            className="d-none"
                                            onMouseEnter={() => {
                                                console.log('onMouseEnter');
                                                setShowCatNavigation('block');
                                            }}
                                        >
                                            Categories
                                        </span>
                                        <form
                                            className="SearchAnything"
                                            action={`${BASEURL}courses`}
                                            autoComplete="off"
                                        >
                                            <div
                                                className="input-box"
                                                style={{ position: 'relative' }}
                                            >
                                                <input
                                                    type="text"
                                                    name="keyword"
                                                    placeholder="Search anything"
                                                />
                                                <button type="button">
                                                    <Link to="/courses-all">
                                                        <i className="fal fa-search" />
                                                    </Link>
                                                </button>
                                                <div
                                                    className="SearchAnythingResult"
                                                    style={{
                                                        display:
                                                            KeySearchCourses.length === 0 &&
                                                            KeySearchInstructor.length === 0 &&
                                                            ShowKeyWords.length === 0
                                                                ? 'none'
                                                                : CommonData.showKeywordSearch,
                                                    }}
                                                >
                                                    {ShowKeyWords.map((si) => (
                                                        <Link
                                                            to={`/courses?keyword=${si.value}`}
                                                            key={si.value}
                                                            className="KeySearchDivContent d-block"
                                                            onClick={() => {
                                                                setKeySearchCourses([]);
                                                                setShowKeyWords([]);
                                                            }}
                                                        >
                                                            <i className="fal fa-search mr-3"></i>
                                                            {si.value}
                                                        </Link>
                                                    ))}
                                                    {KeySearchCourses.map((singi) => (
                                                        <div
                                                            key={singi.TheCourseID}
                                                            className="KeySearchDivContent"
                                                        >
                                                            <Link
                                                                to={`/course/${singi.slug}`}
                                                                onClick={() => {
                                                                    // setKeySearchDiv('none');
                                                                    setKeySearchCourses([]);
                                                                }}
                                                                style={{ display: 'block' }}
                                                            >
                                                                <img
                                                                    src={`${UPLOADNODEURL}${singi.image}`}
                                                                    alt={singi.title}
                                                                />
                                                                <div>
                                                                    <b
                                                                        style={{
                                                                            display: 'block',
                                                                            fontWeight: '700',
                                                                        }}
                                                                    >
                                                                        {singi.title}
                                                                    </b>
                                                                    <div className="d-flex">
                                                                        <div>
                                                                            <small>By {singi.InstructorDisplay}</small>
                                                                        </div>
                                                                        <div>
                                                                            &nbsp;
                                                                            <i className="far fa-globe"></i> <small>{singi.language}</small>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    style={{ clear: 'both' }}
                                                                ></div>
                                                            </Link>
                                                        </div>
                                                    ))}
                                                    {KeySearchInstructor.map((singi) => (
                                                        <div
                                                            key={singi.tid}
                                                            className="KeySearchDivContent"
                                                        >
                                                            <Link
                                                                to={`/instructor/profile/${singi.slug}`}
                                                                onClick={() => {
                                                                    // setKeySearchDiv('none');
                                                                    setKeySearchInstructor([]);
                                                                }}
                                                                style={{ display: 'block' }}
                                                            >
                                                                <img
                                                                    src={`${BASEAPIURL}/admin/instructure/profileimage/${singi.tid}`}
                                                                    alt={singi.name}
                                                                />
                                                                <div>
                                                                    <b
                                                                        style={{
                                                                            display: 'block',
                                                                            fontWeight: '700',
                                                                        }}
                                                                    >
                                                                        {singi.name}
                                                                    </b>
                                                                    <p>
                                                                        <small>Instructor</small>
                                                                    </p>
                                                                </div>
                                                                <div
                                                                    style={{ clear: 'both' }}
                                                                ></div>
                                                            </Link>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <div className="col-lg-5 col-md-5 col-12">
                                    <div className="appie-btn-box">
                                        {type === 'instructor' ? (
                                            <>
                                                <Link to="/">
                                                    <span className="text-dark">Learner</span>
                                                </Link>
                                                <Link
                                                    className="login-btn"
                                                    to="/instructor-login"
                                                    style={{
                                                        lineHeight: 'initial',
                                                        padding: '5px 20px',
                                                        display:
                                                            UserType === 'instructor' ? '' : 'none',
                                                    }}
                                                >
                                                    My Account
                                                    <br />
                                                    <small>({UserName})</small>
                                                </Link>
                                                <Link
                                                    className="login-btn"
                                                    to="/instructor-login"
                                                    style={{
                                                        display:
                                                            UserType === 'instructor' ? 'none' : '',
                                                    }}
                                                >
                                                    Sign in
                                                </Link>
                                                <Link
                                                    className="signup-btn ml-1"
                                                    to="/instructor-register"
                                                    style={{
                                                        display:
                                                            UserType === 'instructor'
                                                                ? 'none'
                                                                : 'none',
                                                    }}
                                                >
                                                    Signup
                                                </Link>
                                            </>
                                        ) : (
                                            <>
                                                <Link to="/instructor">
                                                    <span className="text-dark">
                                                        <b>Instructor</b>
                                                    </span>
                                                </Link>
                                                <Link
                                                    className="login-btn"
                                                    to="/user/login"
                                                    style={{
                                                        display:
                                                            UserType === 'student' ? 'none' : '',
                                                    }}
                                                >
                                                    Sign in
                                                </Link>
                                                <Link
                                                    className="signup-btn ml-1"
                                                    to="/user/register"
                                                    style={{
                                                        display:
                                                            UserType === 'student'
                                                                ? 'none'
                                                                : 'none',
                                                    }}
                                                >
                                                    Signup
                                                </Link>
                                                <Link
                                                    className="login-btn ml-1"
                                                    to="/user/dashboard"
                                                    style={{
                                                        display:
                                                            UserType === 'student' ? '' : 'none',
                                                    }}
                                                >
                                                    My Courses
                                                </Link>
                                            </>
                                        )}
                                        <Link className="signup-btn ml-1" to="/checkout/cart">
                                            <i className="fal fa-shopping-cart" />
                                            &nbsp;{Number(OnCart)} in Cart
                                        </Link>
                                        {/* <Link className="appie-icon-box" to="/checkout/cart">
                                            <i className="fal fa-shopping-cart" />
                                            <span className="cartCounter">{Number(OnCart)}</span>
                                        </Link> */}
                                        <div className="appie-icon-box ml-1">
                                            <span className="showLangOptCont">
                                                <a
                                                    href="/"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                    }}
                                                >
                                                    <i className="fal fa-globe" />
                                                </a>
                                                <div className="showLangOpt">English</div>
                                            </span>
                                            {/* <Link className="appie-icon-box" to="/checkout/cart">
                                                <i className="fal fa-shopping-cart" />
                                                <span className="cartCounter">
                                                    {Number(OnCart)}
                                                </span>
                                            </Link> */}
                                            <Link
                                                className="appie-icon-box d-none"
                                                to="/user/page/MyFavourite"
                                            >
                                                <i className="fal fa-heart" />
                                            </Link>
                                            {/* <a href="#">
                                                <i className="fal fa-bell" />
                                            </a> */}
                                            {type !== 'instructora' && UserType === 'student' ? (
                                                <div className="ProfileMenuCont">
                                                    <img
                                                        src={`${UPLOADNODEURL}${
                                                            userData.photo &&
                                                            userData.photo !== null &&
                                                            userData.photo !== ''
                                                                ? userData.photo
                                                                : defaultProfilePicture
                                                        }`}
                                                        alt={userData.name}
                                                        className=""
                                                    />
                                                    <div className="ProfileMenu">
                                                        <Link to="/user/page/profilesetting">
                                                            <h6>{userData.name}</h6>
                                                            {userData.email}
                                                        </Link>
                                                        <hr />
                                                        <Link to="/user/dashboard">
                                                            My Learning
                                                        </Link>
                                                        <Link to="/user/page/MyFavourite">
                                                            My Wishlist
                                                        </Link>
                                                        <Link to="/user/page/updateprofilesetting">
                                                            Account Setting
                                                        </Link>
                                                        <Link to="/user/page/changePassword">
                                                            Change Password
                                                        </Link>
                                                        <Link
                                                            to="/user/dashboard"
                                                            onClick={() => {
                                                                LogOutAll();
                                                            }}
                                                        >
                                                            Logout
                                                        </Link>
                                                    </div>
                                                </div>
                                            ) : (
                                                ''
                                            )}

                                            {type !== 'instructor1' && UserType === 'instructor' ? (
                                                <div className="ProfileMenuCont">
                                                    <img
                                                        src={`${BASEAPIURL}/admin/instructure/profileimage/${localStorage.getItem(
                                                            'tid'
                                                        )}`}
                                                        alt={localStorage.getItem('username')}
                                                        className=""
                                                    />
                                                    <div className="ProfileMenu">
                                                        <Link to="/instructor/page/profilesetting">
                                                            <h6>
                                                                {localStorage.getItem('username')}
                                                            </h6>
                                                            {/* {userData.email} */}
                                                        </Link>
                                                        <hr />
                                                        <Link to="/instructor/page/mycourses">
                                                            My Courses
                                                        </Link>
                                                        <Link to="/instructor/page/myquestionbank">
                                                            My Question Bank
                                                        </Link>
                                                        <Link to="/instructor/page/updateprofilesetting">
                                                            Account Setting
                                                        </Link>
                                                        <Link to="/instructor/page/changePassword">
                                                            Change Password
                                                        </Link>
                                                        <Link
                                                            to="/user/dashboard"
                                                            onClick={() => {
                                                                LogOutAll();
                                                            }}
                                                        >
                                                            Logout
                                                        </Link>
                                                    </div>
                                                </div>
                                            ) : (
                                                ''
                                            )}
                                            <span className="mr-3"></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    className="navSection navSection-sjain"
                    style={{ display: pageName === 'courseDetail' ? 'none' : '' }}
                >
                    <div className="">
                        <div className="header-nav-box">
                            <div className="align-items-center">
                                <div className="">
                                    <div className="appie-header-main-menu">
                                        <Navigation
                                            setShowCatNavigation={setShowCatNavigation}
                                            setMainCatId={setMainCatId}
                                            setCatId={setCatId}
                                            setSubCatId={setSubCatId}
                                            setCourseListLink={setCourseListLink}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <div style={{ position: 'relative' }}>
                <a
                    href="/"
                    onClick={(e) => {
                        e.preventDefault();
                        if (ShowCatNavigation === 'block') {
                            setShowCatNavigation('none');
                            setMainCatId('');
                        }
                    }}
                    id="closeCatNavigationBtn"
                    className="d-none"
                >
                    stringify
                </a>
                <div
                    className={`categoryNanaCont ${
                        ShowCatNavigation === 'block' ? 'showOnHover' : ''
                    }`}
                    style={{ display: ShowCatNavigation }}
                    onClick={() => {
                        setShowCatNavigation('none');
                    }}
                >
                    <div
                        className="container-fluid"
                        style={{ maxWidth: '1024px' }}
                        onMouseLeave={() => {
                            setShowCatNavigation('none');
                        }}
                    >
                        <div className="row">
                            <div
                                className="col-lg-2 d-none col-md-2 p-0 cmc_cont"
                                style={{ maxWidth: '180px' }}
                            >
                                {CoursesMainCategory().map((s) => (
                                    <div
                                        className="text-center MainDivCont"
                                        key={s.id}
                                        onMouseEnter={() => {
                                            setMainCatId(s.id);
                                            setCatId(0);
                                            setSubCatId(0);
                                            setCourseListLink(s.slug);
                                        }}
                                    >
                                        <div className="imgBox">
                                            <Link
                                                to={s.slug}
                                                style={{
                                                    border: `2px solid ${
                                                        s.id === MainCatId ? '#6c0095' : '#F5F5F5'
                                                    }`,
                                                }}
                                            >
                                                <img src={s.image} alt={s.title} />
                                            </Link>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div
                                className="col-lg-4 col-md-4 p-0 cmc_cont"
                                style={{ borderLeft: '2px solid #f5f5f5' }}
                            >
                                {CatiData.map((s) => (
                                    <Link
                                        to={`${CourseListLink}?category=${s.catid}`}
                                        key={s.catid}
                                        className={`cmc_linkcat ${
                                            CatId === s.catid ? 'cmc_linkcat_active' : ''
                                        }`}
                                        catid={CatId}
                                        onMouseEnter={() => {
                                            setCatId(s.catid);
                                            setSubCatId(0);
                                        }}
                                    >
                                        {s.title}
                                        <i className="pull-right fas fa-angle-right"></i>
                                        <div style={{ clear: 'both' }}></div>
                                    </Link>
                                ))}
                            </div>
                            <div
                                className="col-lg-4 col-md-4 p-0 cmc_cont"
                                style={{
                                    borderLeft: '2px solid #f5f5f5',
                                    display: SubCatiData.length === 0 ? 'none' : '',
                                }}
                            >
                                {SubCatiData.map((s) => (
                                    <Link
                                        to={`${CourseListLink}?category=${CatId}&subcategory=${s.catid}`}
                                        key={s.catid}
                                        catid={SubCatId}
                                        className={`cmc_linkcat ${
                                            SubCatId === s.catid ? 'cmc_linkcat_active' : ''
                                        }`}
                                        onMouseEnter={() => {
                                            setSubCatId(s.catid);
                                        }}
                                    >
                                        {s.title}
                                        <i className="pull-right fas fa-angle-right"></i>
                                    </Link>
                                ))}
                            </div>
                            <div
                                className="col-lg-6 col-md-6 p-0 cmc_cont"
                                style={{
                                    borderLeft: '2px solid #f5f5f5',
                                    display: CourseData.length === 0 ? 'none' : '',
                                }}
                            >
                                {CourseData.map((s, i) => (
                                    <>
                                        {i < 12 ? (
                                            <Link
                                                onClick={() => {
                                                    setShowCatNavigation('none');
                                                }}
                                                to={`/course/${s.slug}`}
                                                key={s.slug}
                                                className="cmc_linkcat"
                                                style={{ display: 'flex' }}
                                                title={s.title}
                                            >
                                                <b className="CLB_course_title_small">
                                                    {s.titleShow && s.titleShow !== ''
                                                        ? s.titleShow
                                                        : s.title}
                                                </b>
                                            </Link>
                                        ) : (
                                            <Link
                                                onClick={() => {
                                                    setShowCatNavigation('none');
                                                }}
                                                to={CourseListLink}
                                                key={s.slug}
                                                className="cmc_linkcat"
                                                style={{ display: 'flex' }}
                                                title={s.title}
                                            >
                                                <b style={{ color: '#7f13ab' }}>View All</b>
                                            </Link>
                                        )}
                                    </>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Header;
