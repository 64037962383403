import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Error from './components/Error';
import Loader from './components/Helper/Loader';
import ScrollToTop from './components/Helper/ScrollToTop';

import Gallery from './components/Institute/Galleries';


function RoutesHere() {
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        window.scrollTo(0, 0);
    });
    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 400);
    });
    return (
        <>
            {loading && (
                <div className={`appie-loader ${loading ? 'active' : ''}`}>
                    <Loader />
                </div>
            )}
            <div className={`appie-visible ${loading === false ? 'active' : ''}`}>
                {/* <Router basename="/docsta/learn"> */}
                <Router>
                    <ScrollToTop>
                        <Routes>
                            <Route exact path="/" element={<Gallery />} />
                            <Route exact path="/error" element={<Error />} />
                            <Route path="*" element={<Gallery />} />

                        </Routes>
                        {/* <Navigate from="/institute" to="/" />  <Route path="*" element={<Navigate to="/Error" />} /> */}
                    </ScrollToTop>
                </Router>
            </div>
        </>
    );
}

export default RoutesHere;
