import React from 'react';
import Routes from './Routes';
// import TagManager from 'react-gtm-module'

function App() {
    // const tagManagerArgs = {
    //     gtmId: 'GTM-NFCGQM34'
    // }
    
    // TagManager.initialize(tagManagerArgs)
    return <Routes />;
}

export default App;
